import { ref } from 'vue'
import { client } from '@/contentful/config'


const resume = ref({})
const getResumeError = ref(null)

const getResume = async (tag) => {
    const res = await client.getEntries({'content_type': 'resume', 'fields.tag': tag})
    if(!res.items.length) {
        getResumeError.value = 'not found'
        return
    }
    resume.value = res.items[0].fields
    resume.value.person = resume.value.person.fields
    resume.value.education = resume.value.education.map((edu) => {
        return edu.fields
    })
    resume.value.work = resume.value.work.map((work) => {
        return work.fields
    })
}


const useGetResume = () => {
    return { resume, getResume, getResumeError }
}

export default useGetResume
