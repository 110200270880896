<template>
  <iframe id="pdf_iframe" type="application/pdf" width="100%" height="800px">
  </iframe>
</template>

<script>
import { watch } from 'vue'
import { useRoute } from 'vue-router'
import useGenerateResume from "@/composables/useGenerateResume"
import useGetResume from "@/composables/useGetResume"

export default {
    setup() {
        const { generateResume } = useGenerateResume()
        const { resume, getResume, getResumeError } = useGetResume()
        const route = useRoute()
        let tag = route.params.id ? route.params.id : 'default'
        getResume(tag)

        watch(resume, () => {
            if(resume.value){
                generateResume(resume.value)
            }
        })

        watch(getResumeError, () => {
            if(getResumeError.value) {
                window.location.href = 'https://ckbrox.com'
            }
        })
        
    }
}
</script>

<style>

</style>