import { jsPDF } from "jspdf";

const generateResume = async (resume) => {
    var iframe = document.getElementById('pdf_iframe');
    let pageWidth = 595;
    let pageHeight = 842;
    let paddingLeft = 40;
    let paddingTop = 60;
    let paddingRight = 40;
    let paddingBottom = 40;
    let innerWidth = pageWidth - paddingRight - paddingLeft;
    let x = paddingLeft, y = paddingTop;
    let column2Width = innerWidth / 3.2;
    let standardFontSize = 10;
    var verticalSpacing = 18;

    let doc = new jsPDF('p', 'pt', [pageWidth, pageHeight]);


    function drawHeader(x, y) {
        let originX = x;
        let originY = y
        // profile picture
        const imageWidth = 80;
        const imageHeight = 80;
        doc.addImage(profilepicture, paddingLeft, paddingTop, imageWidth, imageHeight)

        /*COLUMN 1*/
        // name
        doc.setFontSize(22)
        doc.setFont(undefined, 'bold');
        doc.setTextColor(0,0,0)
        originX = originX + imageWidth + 20
        originY = originY + 40;
        doc.text(resume.person.name, originX, originY)

        //title
        doc.setFontSize(14)
        doc.setFont(undefined, 'normal')
        doc.setTextColor(76,78,83)
        doc.text(resume.title, originX, originY + 20)


        /*COLUMN 2*/
        doc.setFontSize(standardFontSize)
        // socials section
        x = pageWidth - paddingRight - column2Width + 15
        originX = x
        originY = y
        var textX = originX + 12;
        var iconWidth = 8;
        var iconHeight = 8;

        function drawRow(x, y, text, textWidth = 0) {
            doc.text(text, x, y, {maxWidth: textWidth})
        }

        function drawIcon(x, y, icon, width = iconWidth, height = iconHeight) {
            doc.addImage(icon, x, y, width, height);
        }

        if (resume.person.phone) {
            drawIcon(originX - 12, originY - 8, phoneIcon)
            drawRow(textX, originY, resume.person.phone)
            originY += verticalSpacing
        }
        if (resume.person.email) {
            drawIcon(originX - 12, originY - 8, emailIcon)
            drawRow(textX, originY, resume.person.email)
            originY += verticalSpacing
        }
        if (resume.person.linkedIn) {
            drawIcon(originX - 12, originY - 8, linkedinIcon)
            drawRow(textX, originY, resume.person.linkedIn)
            originY += verticalSpacing
        }
        if (resume.person.addressLine1) {
            drawIcon(originX - 12, originY - 8, locationIcon, undefined, iconHeight + 4)
            drawRow(textX, originY, resume.person.addressLine1)
            originY += verticalSpacing
        }
        if (resume.person.addressLine2) {
            drawRow(textX, originY, resume.person.addressLine2)
        }

        return y + 80
    }

    function setNormalFont(){
        doc.setFont(undefined, 'normal');
        doc.setTextColor(76,78,83);
    }

    function setBoldFont(){
        doc.setFont(undefined, 'bold');
        doc.setTextColor(44,46,52);
    }

    var rowHeight = 16;
    function drawHeaderRow(x, y, text, x2= pageWidth - paddingRight) {
        setBoldFont()
        doc.setFontSize(14)
        doc.text(text,x,y)
        doc.setFontSize(standardFontSize)
        y+= 10;
        doc.line(x, y, x2, y)
        setNormalFont();
    }

    function drawColumn1(x, y, width) {
        drawHeaderRow(x, y, "WORK EXPERIENCE", x+width)
        var row2RightOffset = 25
        y+=30

        function checkPageBreak(){
            if(y+rowHeight > pageHeight - paddingBottom){
                doc.addPage();
                x = paddingLeft;
                y= paddingTop
            }
        }
        

        resume.work.forEach((work) => {
            checkPageBreak()
            setBoldFont()
            doc.text(work.title, x,y)
            setNormalFont()
            y+= rowHeight + 2;
            doc.text(work.location + " | " +  work.years, x, y)
            y+= rowHeight + 2;

            var texts = doc.splitTextToSize(work.description, width)
            texts.forEach(text=>{
                checkPageBreak()
                doc.text(text, x, y)
                y+= rowHeight
            })
            y+= 2
            var xPadding = 22
            if(work.highlights) {
                work.highlights.forEach(highlight=>{
                    checkPageBreak()
                    doc.circle(x + xPadding/2,y, 2, 'F')
                    doc.text(highlight, x + xPadding, y + 2)
                    y+= rowHeight
                })
            }
     
            y+=verticalSpacing;
        })
    }


    function drawColumn2(x, y, width) {
        drawHeaderRow(x, y, "ABOUT ME")
        function drawMultiLineTexts(text, x,y, rowSize, textWidth = width){
            var texts = doc.splitTextToSize(text, textWidth)
            texts.forEach(text=>{
                doc.text(text, x, y)
                y+= rowSize
            })
            return y;
        }

        function drawAboutMe(y) {
            drawHeaderRow(x, y, "ABOUT ME")
            y+=30;
            y = drawMultiLineTexts(resume.aboutMe, x, y, rowHeight)

            return y + verticalPadding;
        }

        var row2RightOffset = 25
        function drawEducation(y) {
            drawHeaderRow(x, y, "EDUCATION")
            y+=30
            resume.education.forEach(education=>{
                setBoldFont()
                y = drawMultiLineTexts(education.degree, x, y, rowHeight, width-row2RightOffset)
                setNormalFont()
                y = drawMultiLineTexts(education.school, x, y+5, rowHeight, width-row2RightOffset)
                y = drawMultiLineTexts(education.years, x, y+5, rowHeight, width-row2RightOffset)
            })
            return y + verticalPadding
        }

        function drawSkills(y) {
            drawHeaderRow(x, y, "SKILLS")
            y+=30
            var xPadding = 22
            resume.skills.forEach(skill=>{
                doc.circle(x + xPadding/2,y+2, 2, 'F')
                y = drawMultiLineTexts(skill, x + xPadding, y+5, rowHeight, width-row2RightOffset - xPadding-12)
            })
        }

        y = drawAboutMe(y);
        y = drawEducation(y);
        drawSkills(y);

    }

    y = drawHeader(x, y)

    // horizontal divider
    const verticalPadding = 20
    const horizontalPadding = 20
    y += verticalPadding
    doc.setDrawColor(0, 0, 0)
    doc.setLineWidth(.25)
    doc.line(x, y, pageWidth - paddingRight, y)


    // draw vertical divider
    var lineX = pageWidth - paddingRight - column2Width - horizontalPadding - 20
    var lineY = y + verticalPadding;
    var lineY2 = pageHeight - paddingBottom
    doc.line(lineX, lineY, lineX, lineY2)

    drawColumn2(lineX + horizontalPadding + 25, lineY + 10, column2Width - 15)

    drawColumn1(x, lineY+10, innerWidth - column2Width - horizontalPadding*3 -20);

    iframe.src = doc.output('bloburl');
}


const useGenerateResume = () => {
    return { generateResume }
}

export default useGenerateResume